import React from 'react'
import { graphql } from 'gatsby'
import Responsive from 'react-responsive'

import Layout from '../components/layout'
import Box from '../components/box'
import Name from '../components/name'
import Item from '../components/item'
import Header from '../components/header'
import LinkPage from '../components/linkpage'

const pagetitle = 'hello'

const IndexPage = ({
  data: {
    allBaseJson: { edges },
  },
}) => {
  const MediaItems = edges
    .filter(edge => edge.node.item.category === 'media') // You can filter your posts based on some criteria
    .map(edge => <Item key={edge.node.id} items={edge.node.item} />)

  const SocialItems = edges
    .filter(edge => edge.node.item.category === 'social') // You can filter your posts based on some criteria
    .map(edge => <Item key={edge.node.id} items={edge.node.item} />)

  const NewsItems = edges
    .filter(edge => edge.node.item.category === 'news') // You can filter your posts based on some criteria
    .map(edge => <Item key={edge.node.id} items={edge.node.item} />)

  const WorkItems = edges
    .filter(edge => edge.node.item.category === 'work') // You can filter your posts based on some criteria
    .map(edge => <Item key={edge.node.id} items={edge.node.item} />)

  const TechItems = edges
    .filter(edge => edge.node.item.category === 'tech') // You can filter your posts based on some criteria
    .map(edge => <Item key={edge.node.id} items={edge.node.item} />)

  const Desktop = props => <Responsive {...props} minWidth={992} />
  const Mobile = props => <Responsive {...props} maxWidth={991} />

  return (
    <Layout>
      <Desktop>
        <Header title={pagetitle} size="destkop" />
        <Box>
          <Name cat="social" />
          {SocialItems}
        </Box>
        <Box>
          <Name cat="news" />
          {NewsItems}
        </Box>
        <Box>
          <Name cat="tech" />
          {TechItems}
        </Box>
        <Box>
          <Name cat="work" />
          {WorkItems}
        </Box>
        <Box>
          <Name cat="media" />
          {MediaItems}
        </Box>
        <LinkPage linktxt="serveur" linkto="serveur" />
      </Desktop>
      <Mobile>
        <Header size="mobile" />
        <Box>
          <Name cat="news" />
          {NewsItems}
        </Box>
        <Box>
          <Name cat="tech" />
          {TechItems}
        </Box>
        <Box>
          <Name cat="work" />
          {WorkItems}
        </Box>
        <Box>
          <Name cat="media" />
          {MediaItems}
        </Box>
      </Mobile>
    </Layout>
  )
}

export const pageQuery = graphql`
  query itemsbase {
    allBaseJson {
      edges {
        node {
          id
          item {
            title
            link
            category
            logo {
              childImageSharp {
                fixed(width: 16, quality: 85) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
